import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";

const Landingpageapp = React.lazy(() => import("./components/Landingpageapp"));
const LandingDocumentSummary = React.lazy(() => import("./components/LandingPage/LandingDocumentSummary"));

const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthForgot = React.lazy(() => import("./Authentication/Forgot"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"));

const App = React.lazy(() => import("./components/app"));
const SearchHub = React.lazy(() => import("./components/SearchHub/SearchHub"));
const UserDashboard = React.lazy(() => import("./components/Dashboard/UserDashboard"));
const UserCompanyQNA = React.lazy(() => import("./components/Dashboard/UserCompanyQNA"));
const UserAccount = React.lazy(() => import("./components/Useraccount/UserAccount"));
const AdminDashboard = React.lazy(() => import("./components/Dashboard/AdminDashboard"));
const AdminCompanies = React.lazy(() => import("./components/Companies/AdminCompanies"));
const UserCompanies = React.lazy(() => import("./components/Companies/UserCompanies"));
const Addcompany = React.lazy(() => import("./components/Companies/Addcompany"));
const Editcompany = React.lazy(() => import("./components/Companies/Editcompany"));
const AdminExecutives = React.lazy(() => import("./components/Executives/AdminExecutives"));
const UserExecutives = React.lazy(() => import("./components/Executives/UserExecutives"));
const Addexecutive = React.lazy(() => import("./components/Executives/Addexecutive"));
const Editexecutive = React.lazy(() => import("./components/Executives/Editexecutive"));
const UserDocuments = React.lazy(() => import("./components/Documents/UserDocuments"));
const AdminDocuments = React.lazy(() => import("./components/Documents/AdminDocuments"));
const Adddocument = React.lazy(() => import("./components/Documents/Adddocument"));
const Editdocument = React.lazy(() => import("./components/Documents/Editdocument"));
const Subscriptions = React.lazy(() => import("./components/Subscriptions/Subscriptions"));
const Activeusers = React.lazy(() => import("./components/Activeusers/Activeusers"));
const Activesubscribers = React.lazy(() => import("./components/Activesubscribers/Activesubscribers"));
const Notifications = React.lazy(() => import("./components/Notifications/Notifications"));
const Profile = React.lazy(() => import("./components/Profile/Profile"));
const Support = React.lazy(() => import("./components/Support/Support"));
const UserSettings = React.lazy(() => import("./components/Settings/UserSettings"));
const AdminSettings = React.lazy(() => import("./components/Settings/AdminSettings"));
const Privacy = React.lazy(() => import("./components/Privacy/Privacy"));

const Root = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
          <Route index path={`${process.env.PUBLIC_URL}/`} element={<Landingpageapp />} />
            <Route path={`${process.env.PUBLIC_URL}/document-summary`} element={<LandingDocumentSummary />} />
            <Route
              path={`${process.env.PUBLIC_URL}/search-hub`}
              element={<SearchHub />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/privacy`}
              element={<Privacy />}
            />
            <Route element={<AuthLogin />} />
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              element={<AuthLogin />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/forgot`}
              element={<AuthForgot />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/register`}
              element={<AuthSignup />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/user-dashboard`}
              element={<UserDashboard />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/company-query`}
              element={<UserCompanyQNA />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/user-account`}
              element={<UserAccount />}
            />
            <Route path={`${process.env.PUBLIC_URL}/`}
              element={<App />}>
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-dashboard`}
                  element={<AdminDashboard />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-companies`}
                  element={<AdminCompanies />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-companies`}
                  element={<UserCompanies />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/addcompany`}
                  element={<Addcompany />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/editcompany`}
                  element={<Editcompany />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-executives`}
                  element={<AdminExecutives />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-executives`}
                  element={<UserExecutives />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/addexecutive`}
                  element={<Addexecutive />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/editexecutive`}
                  element={<Editexecutive />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-documents`}
                  element={<UserDocuments />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-documents`}
                  element={<AdminDocuments />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/adddocument`}
                  element={<Adddocument />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/editdocument`}
                  element={<Editdocument />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/my-subscriptions`}
                  element={<Subscriptions />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/active-users`}
                  element={<Activeusers />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/active-subscribers`}
                  element={<Activesubscribers />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/notifications`}
                  element={<Notifications />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/profile`}
                  element={<Profile />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/support`}
                  element={<Support />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-settings`}
                  element={<UserSettings />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-settings`}
                  element={<AdminSettings />}
                />
              </Route>
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);